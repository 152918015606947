import { useRouter } from "next/router";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { CollegePLPProps } from "./types";

export interface CollegePLPContextProps extends CollegePLPProps {}

export const PlpContextData = createContext<CollegePLPContextProps | undefined>(undefined);
export const PlpContextSetData = createContext<Dispatch<SetStateAction<CollegePLPContextProps>>>(undefined);

export const PlpContextProvider = ({ children, plpData }: { children: ReactNode; plpData: CollegePLPContextProps }) => {
  const [data, setData] = useState<CollegePLPContextProps>(plpData);
  const { query } = useRouter();

  useEffect(() => {
    setData(plpData);
  }, [query]);

  return (
    <PlpContextData.Provider value={data}>
      <PlpContextSetData.Provider value={setData}>{children}</PlpContextSetData.Provider>
    </PlpContextData.Provider>
  );
};
export const usePlpData = (): CollegePLPContextProps => useContext(PlpContextData);
export const useDispatchPlpData = () => useContext(PlpContextSetData);
