/**
 * Validators for react-hook-form
 */

import { Temporal } from "temporal-polyfill";
import { persianDigits, englishDigits } from "@/common/utils/string";

export const patterns = {
  // match: anystring@anystring.anystring
  emailRePattern: /^[a-z0-9._%+-]+@[a-z0-9._-]+\.[a-z]{2,}$/i,
  // match mobile phone numbers: 09123456789 +989123456789 00989123456789
  phoneRePattern: /^((\+|00)98|0)\d{10}$/,
  // match mobile phone numbers: 123 456 7890, 123.456.7890, +91 (123) 456-7890
  foreignPhoneRePattern: /^((\+|00)\d{1,2}\s?)\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
};

export const requiredValidator = (required: boolean) => !!required && "این فیلد لازم است.";

export const maxLengthValidator = (max: number) => ({
  value: max,
  message: `حداکثر ${persianDigits(max)} کاراکتر مجاز است.`,
});

export const minLengthValidator = (min: number) => ({
  value: min,
  message: `باید دست‌کم ${persianDigits(min)} کاراکتر باشد.`,
});

export const maxValidator = (max: number) => ({
  value: max,
  message: `مقدار ورودی باید کوچک‌تر یا مساوی ${persianDigits(max)} باشد.`,
});

export const minValidator = (min: number) => ({
  value: min,
  message: `مقدار ورودی باید بزرگتر یا مساوی ${persianDigits(min)} باشد.`,
});

export const emailValidator = (email) =>
  email && !patterns.emailRePattern.test(email) && "لطفا یک ایمیل معتبر بنویسید.";

export const phoneNumberValidator = (phoneNumber) =>
  patterns.phoneRePattern.test(englishDigits(phoneNumber)) ||
  patterns.foreignPhoneRePattern.test(englishDigits(phoneNumber)) ||
  "لطفا یک شماره تماس معتبر وارد کنید.";

interface DateValidatorOptions {
  allowedInFuture?: Temporal.Duration;
  allowedInPast?: Temporal.Duration;
  forceFourDigitYear?: boolean;
  calendar?: Temporal.CalendarLike; // force four-digit year in what calendar?
}
export const dateValidator =
  ({ allowedInFuture, allowedInPast, forceFourDigitYear = true, calendar = "persian" }: DateValidatorOptions) =>
  (isoDate: string) => {
    let date: Temporal.PlainDate;
    try {
      date = Temporal.PlainDate.from(isoDate).withCalendar(calendar);
    } catch (err) {
      return "لطفاً یک تاریخ معتبر وارد کنید.";
    }

    if (forceFourDigitYear && date.year < 1000) return "لطفاً سال را به صورت ۴ رقمی وارد کنید.";

    try {
      if (
        allowedInPast !== undefined &&
        Temporal.PlainDate.compare(date, Temporal.Now.plainDate(calendar).subtract(allowedInPast)) < 0
      )
        return "لطفاً یک تاریخ معتبر وارد کنید.";
      if (
        allowedInFuture !== undefined &&
        Temporal.PlainDate.compare(date, Temporal.Now.plainDate(calendar).add(allowedInFuture)) > 0
      )
        return "لطفاً یک تاریخ معتبر وارد کنید.";
    } catch (err) {
      // TODO: remove this try/except after chrome/firefox timezone bug on MacOS Sonoma fixed
      console.error(err);
      return true;
    }
    return true;
  };
